// @codekit-append "../bower_components/isotope-layout/dist/isotope.pkgd.min.js";
// @codekit-append "cells-by-row.js";
// @codekit-append "fit-columns.js";
// @codekit-append "jquery.inview.min.js";


jQuery(document).foundation();

jQuery(window).resize(function() {
  jQuery("section.modul--instagram div.post div").each(function() {
    jQuery(this).css("height", jQuery(this).outerWidth()+"px");
  });
});

jQuery(document).ready(function() {


  if ( jQuery( ".grid" ).length ) {
    var jQuerygrid = jQuery('.grid').isotope({
      layoutMode: 'cellsByRow',
      itemSelector: '.grid-item'
    });
  }

  jQuery('body.front .modul--heroimage-small-front').on('inview', function(event, isInView) {
    if (isInView) {
      jQuery("div.sidecaption-logo").fadeOut();
      console.log("X");
    } else {
      jQuery("div.sidecaption-logo").fadeIn();
      console.log("Y");
    }
  });
  /*
  if ( jQuery( ".grid-marken" ).length ) {
    var jQuerygrid = jQuery('.grid-marken').isotope({
      layoutMode: 'cellsByRow',
      itemSelector: '.grid-item'
    });
  }
*/
  var filterFns = {
    // show if number is greater than 50
    numberGreaterThan50: function() {
      var number = jQuery(this).find('.number').text();
      return parseInt( number, 10 ) > 50;
    },
    // show if name ends with -ium
    ium: function() {
      var name = jQuery(this).find('.name').text();
      return name.match( /iumjQuery/ );
    }
  };

  jQuery( "div.mainmenu ul li" ).mouseover(function() {
    var myindex =  jQuery(this).index();
    jQuery('div.nav--overlay img:not(.place)').fadeOut();
    jQuery('div.nav--overlay img[data-hover="'+myindex+'"]').fadeIn();


    //jQuery("div.nav--overlay img[data-hover]").stop().fadeOut( "fast", function() {

    //});
  });

  var menuButton = document.getElementById('menuButton');
  menuButton.addEventListener('click', function (e) {
      menuButton.classList.toggle('is-active');
      jQuery("body").toggleClass('noscroll');
      jQuery("div.nav--overlay").fadeToggle();

      e.preventDefault();
  });

  jQuery(document).on('click','.modul--filter span',function(){
      jQuery(".modul--filter span").removeClass("active");
      jQuery(this).addClass("active");
      var filterValue = jQuery( this ).attr('data-filter');
      filterValue = filterFns[ filterValue ] || filterValue;
      jQuerygrid.isotope({ filter: filterValue });
  });

  jQuery(document).on('click','.modul--filter-marken span',function(){
      jQuery(".modul--filter-marken span").removeClass("active");
      jQuery(this).addClass("active");

  });

  jQuery("section.modul--instagram div.post div").each(function() {
    jQuery(this).css("height", jQuery(this).outerWidth()+"px");
  });


  jQuery(document).on('click','.modul--filter-marken span.filter',function(){
      var filterValue = jQuery( this ).attr('data-filter');

      if(filterValue == "all") {
        jQuery("a.marke-elem").removeClass("hide");
        jQuery("div.marke-elem-letter").removeClass("hide");
      } else {

        jQuery("a.marke-elem").addClass("hide");

        jQuery("a.marke-elem."+filterValue).removeClass("hide");

        jQuery("div.marke-elem-letter").each(function() {
          var letter = jQuery(this);
          if(jQuery(this).find("a:not(.hide)").length == 0) {
            letter.addClass("hide");
            console.log("A");
          } else {
            letter.removeClass("hide");
            console.log("B");
          }
        });


      }



  });



});
